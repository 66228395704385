import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

export const cache = new InMemoryCache({
	dataIdFromObject:object => {
		switch(object.__typename){
			case 'Page':
				return `${defaultDataIdFromObject(object)}:${object.title}`;
			default:
				return defaultDataIdFromObject(object);
		}
	},
	typePolicies:{
		Query:{
			fields:{
				contentModules:{
					keyArgs:(args, context) => {
						return context.variables.infiniteScroll ? ['group', 'theme', 'query', 'type'] : ['page', 'group', 'theme', 'query', 'type'];
					},
					merge(existing = {}, incoming){
						return {
							...incoming,
							...{
								data:[...(existing.data || []), ...incoming.data]
							}
						};
					}
				},
				themes:{
					keyArgs:(args, context) => {
						return context.variables.infiniteScroll ? ['group', 'query'] : ['page', 'group', 'query'];
					},
					merge(existing = {}, incoming){
						return {
							...incoming,
							...{
								data:[...(existing.data || []), ...incoming.data]
							}
						};
					}
				},
				assets:{
					keyArgs:(args, context) => {
						return context.variables.infiniteScroll ? ['directory', 'query', 'uploads', 'mimes'] : ['page', 'directory', 'query', 'uploads', 'mimes'];
					},
					merge(existing = {}, incoming){
						return {
							...incoming,
							...{
								data:[...(existing.data || []), ...incoming.data]
							}
						};
					}
				}
			}
		}
	}
});