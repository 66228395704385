import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { useAuthToken } from './auth';
import { cache } from './cache';

const httpLink = new HttpLink({
    uri:process.env.REACT_APP_API_URL+'/graphql'
});

const authMiddleware = (authToken, i18n) => {
    return new ApolloLink((operation, forward) => {
        const headers = {
            lang:i18n.language
        };
        if (authToken){
            headers.authorization = `Bearer ${authToken}`;
        }
        operation.setContext({
            headers:headers
        });
        return forward(operation);
    });
}

export const useAppApolloClient = (i18n) => {
    const [authToken] = useAuthToken();
    return new ApolloClient({
        link:authMiddleware(authToken, i18n).concat(httpLink),
        cache,
        defaultOptions:{
            mutate:{
                errorPolicy:'all'
            }
        }
    });
}