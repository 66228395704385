export function amtColor(color, percent){
	var num = parseInt(color.replace('#', ''), 16),
		amt = Math.round(2.55 * percent),
		R = (num >> 16) + amt,
		B = (num >> 8 & 0x00FF) + amt,
		G = (num & 0x0000FF) + amt;
	return '#'+(0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
}

export function hexToRGBA(hexCode, opacity){
	var hex = hexCode.replace('#', '');
	if (hex.length === 3){
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	var r = parseInt(hex.substring(0,2), 16),
		g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);
    return 'rgba('+r+', '+g+', '+b+', '+opacity/100+')';
}

export function hexToRGBCode(hexCode){
	var hex = hexCode.replace('#', '');
	if (hex.length === 3){
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	var r = parseInt(hex.substring(0,2), 16),
		g = parseInt(hex.substring(2,4), 16),
        b = parseInt(hex.substring(4,6), 16);
    return r+' '+g+' '+b;
}