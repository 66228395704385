import { cloneElement } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Tooltip.module.scss';

function isReactText(children){
	return ['string', 'number'].includes(typeof children);
}

const Tooltip = (props) => {
	const [isOver, hoverProps] = useHover({delayEnter:0, delayLeave:0});
	const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
		isOpen:isOver,
		placement:props.placement || 'top-center',
		triggerOffset:14
	});
	let trigger;

	if (isReactText(props.children)){
		trigger = (
			<span {...triggerProps} {...hoverProps}>
				{props.children}
			</span>
		);
	} else {
		trigger = cloneElement(props.children, {
			...triggerProps,
			...hoverProps
		});
	}

	return (
		<>
			{trigger}
			{renderLayer(
				<AnimatePresence>
					{isOver &&
						<motion.div
							className={styles.Layer+(props.dark ? ' '+styles['Layer--dark'] : '')}
							initial={{opacity:0, scale:0.9}}
							animate={{opacity:1, scale:1}}
							exit={{opacity:0, scale:0.9}}
							transition={{duration:0.18}}
							{...layerProps}
						>
							<div className={styles.Body}>
								{props.text}
							</div>
							<Arrow
								className={styles.Arrow}
								{...arrowProps}
								backgroundColor={props.dark ? '#1c1e25' : 'white'}
								borderWidth={1}
								roundness={2}
								borderColor="#e3e6ea"
								size={8}
							/>
						</motion.div>
					}
				</AnimatePresence>
			)}
		</>
	);
}

export default Tooltip;