import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './components/Redux/store';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId:process.env.REACT_APP_GTM_KEY
};
if (process.env.REACT_APP_GTM_KEY){
	TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>, document.getElementById('root')
);

reportWebVitals();