import { gql } from '@apollo/client';

export const ENV_QUERY = gql`
    query Env {
        env {
            name
            authUser {
                roles {
                    name
                }
            }
            host
            color
            allowNewRegistrations
            isOwner
            canChangeTheme
            canCreateNewSites
            billingEnabled
            urlPrivacy
            urlTermsOfUse,
            street
            zip
            city
            country
            phone
            phoneLinkable
            email
            translations
        }
    }
`;