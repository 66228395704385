import Badge from '../../Atoms/Badge/Badge';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';

const ErrorBox = (props) => {
	const { t } = useTranslation();

	return (
		<div className="text-center py-5">
			<div className="flex justify-center mb-2">
				<Badge icon="bold-warning" color="red" size="xl" rounded="full" />
			</div>
			<div className="text-md">{props.title || (props.permissions ? t('global.missing_permissions_headline') : t('global.an_error_has_occurred'))}</div>
			<div className="text-xs text-gray-600 mt-1">{props.text || (props.permissions ? t('global.missing_permissions_text') : t('global.please_try_again_later'))}</div>
			{props.button &&
				<div className="mt-5">
					<Button {...props.button} />
				</div>
			}
		</div>
	)
}

export default ErrorBox;