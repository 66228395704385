import { Suspense, lazy, useEffect, useState } from 'react';
import { ApolloProvider, useQuery } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/Toolkit/Organisms/Route/PrivateRoute';
import { amtColor, hexToRGBA, hexToRGBCode } from './components/Toolkit/Utils/Color';
import { ENV_QUERY }  from './components/Queries/Env';
import { ME_QUERY }  from './components/Queries/User';
import SiteError from './components/Toolkit/Molecules/Error/SiteError';
import SiteLoader from './components/Toolkit/Molecules/SiteLoader/SiteLoader';
import { useAppApolloClient } from './config/apolloClient';
import { useAuthToken } from './config/auth';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useLogoutMutation } from './components/Mutations/Auth';

const Dashboard = lazy(() => import('./components/Pages/Dashboard'));
const Edit = lazy(() => import('./components/Pages/Edit'));
const Preview = lazy(() => import('./components/Pages/Preview'));
const PostTypes = lazy(() => import('./components/Pages/PostTypes'));
const ContentModules = lazy(() => import('./components/Pages/ContentModules'));
const Posts = lazy(() => import('./components/Pages/Posts'));
const Appearance = lazy(() => import('./components/Pages/Appearance'));
const Billing = lazy(() => import('./components/Pages/Billing'));
const User = lazy(() => import('./components/Pages/User'));
const Login = lazy(() => import('./components/Pages/Login'));
const RequestPassword = lazy(() => import('./components/Pages/RequestPassword'));
const ResetPassword = lazy(() => import('./components/Pages/ResetPassword'));
const Register = lazy(() => import('./components/Pages/Register'));
const Apps = lazy(() => import('./components/Pages/Apps'));
const NewSite = lazy(() => import('./components/Pages/NewSite'));
const Media = lazy(() => import('./components/Pages/Media'));
const PaymentSuccessful = lazy(() => import('./components/Pages/PaymentSuccessful'));
const CookieInfo = lazy(() => import('./components/Pages/Apps/CookieInfo'));
const CookieConsent = lazy(() => import('./components/Pages/Apps/CookieConsent'));
const GoogleAnalytics = lazy(() => import('./components/Pages/Apps/GoogleAnalytics'));
const GoogleRecaptcha = lazy(() => import('./components/Pages/Apps/GoogleRecaptcha'));
const Forms = lazy(() => import('./components/Pages/Apps/Form/Forms'));
const FormRequests = lazy(() => import('./components/Pages/Apps/Form/Requests'));
const AdminUsers = lazy(() => import('./components/Pages/Admin/Users'));
const AdminPartners = lazy(() => import('./components/Pages/Admin/Partners'));
const AdminOwners = lazy(() => import('./components/Pages/Admin/Owners'));
const AdminDomains = lazy(() => import('./components/Pages/Admin/Domains'));
const SocialLinks = lazy(() => import('./components/Pages/Apps/SocialLinks'));
const CustomScripts = lazy(() => import('./components/Pages/Apps/CustomScripts'));
const CustomCSS = lazy(() => import('./components/Pages/Apps/CustomCSS'));
const Cta = lazy(() => import('./components/Pages/Apps/Cta'));
const StickyButton = lazy(() => import('./components/Pages/Apps/StickyButton'));
const SEOTools = lazy(() => import('./components/Pages/Apps/SEOTools/Index'));
const Redirects = lazy(() => import('./components/Pages/Apps/SEOTools/Redirects'));
const SiteVerification = lazy(() => import('./components/Pages/Apps/SEOTools/SiteVerification'));
const More = lazy(() => import('./components/Pages/More'));
const Developer = lazy(() => import('./components/Pages/Developer'));
const VerifyEmail = lazy(() => import('./components/Pages/VerifyEmail'));
const ChangeEmail = lazy(() => import('./components/Pages/ChangeEmail'));
const AdminLanguages = lazy(() => import('./components/Pages/Admin/Languages'));
const OwnerSectors = lazy(() => import('./components/Pages/Owner/Sectors'));
const OwnerPartners = lazy(() => import('./components/Pages/Owner/Partners'));
const OwnerSites = lazy(() => import('./components/Pages/Owner/Sites'));
const Domains = lazy(() => import('./components/Pages/Domains'));
const AcceptInvitation = lazy(() => import('./components/Pages/AcceptInvitation'));
const Team = lazy(() => import('./components/Pages/Team'));
const Roles = lazy(() => import('./components/Pages/Roles'));
const Invitations = lazy(() => import('./components/Pages/Invitations'));
const Notifications = lazy(() => import('./components/Pages/Notifications'));
const Notification = lazy(() => import('./components/Pages/Notification'));
const GoogleTagmanager = lazy(() => import('./components/Pages/Apps/GoogleTagmanager'));
const CustomEmail = lazy(() => import('./components/Pages/Apps/CustomEmail'));

function App(){
	const [isInit, setIsInit] = useState(false);

	i18n.use(LanguageDetector).use(initReactI18next).init({
	    detection:{
	        checkWhitelist:true
	    },
	    debug:false,
	    interpolation:{
	        escapeValue:false
	    }
	}, () => {
		setIsInit(true);
	});

	return isInit ? <Container i18n={i18n} /> : <SiteLoader />;
}

const Container = (props) => {
	const client = useAppApolloClient(props.i18n);

	return (
        <ApolloProvider client={client}>
	        <Wrapper i18n={props.i18n} />
	    </ApolloProvider>
    );
}

const Wrapper = (props) => {
	const [getToken] = useAuthToken();
	const { logout, loading:loadingLogout } = useLogoutMutation();
	const { loading:loadingEnv, data:dataEnv, error:errorEnv } = useQuery(ENV_QUERY);
	const { loading:loadingMe, data:dataMe, error:errorMe } = useQuery(ME_QUERY, {
		onCompleted:(data) => {
			if (!data.me && getToken){
				logout({
					update:(cache, result) => {
						window.location.href = '/';
			        }
				});
			}
		}
	});

	useEffect(() => {
		if (dataEnv){
			props.i18n.addResourceBundle(props.i18n.language, ['translation'], dataEnv.env.translations);
			const color = dataEnv.env.color;
			document.documentElement.style.setProperty('--color-primary-100', hexToRGBCode(amtColor(color, 40)));
			document.documentElement.style.setProperty('--color-primary-200', hexToRGBCode(amtColor(color, 30)));
			document.documentElement.style.setProperty('--color-primary-300', hexToRGBCode(amtColor(color, 20)));
			document.documentElement.style.setProperty('--color-primary-400', hexToRGBCode(amtColor(color, 10)));
			document.documentElement.style.setProperty('--color-primary-500', hexToRGBCode(color));
			document.documentElement.style.setProperty('--color-primary-600', hexToRGBCode(amtColor(color, -10)));
			document.documentElement.style.setProperty('--color-primary-700', hexToRGBCode(amtColor(color, -20)));
			document.documentElement.style.setProperty('--color-primary-800', hexToRGBCode(amtColor(color, -30)));
			document.documentElement.style.setProperty('--color-primary-900', hexToRGBCode(amtColor(color, -40)));
			document.documentElement.style.setProperty('--shadows-button-primary', 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, '+hexToRGBA(color, 16)+' 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, '+hexToRGBA(color, 8)+' 0px 2px 5px 0px');
			document.documentElement.style.setProperty('--shadows-button-primary-focus', '0 0 0 1px rgba(28, 30, 37, .05), 0 2px 5px 0 rgba(28, 30, 37, .05), 0 1px 2.5px 0 rgba(28, 30, 37, .05), 0 1px 2px 0 rgba(28, 30, 37, .05), '+hexToRGBA(color, 30)+' 0px 0px 0px 4px');
		}
	}, [dataEnv]);

	const globalProps = {
        colorize:true,
        collapsed:true
    };

	return (
		<BrowserRouter>
        	{(loadingEnv || loadingMe) ?
        		<SiteLoader />
        	:
        		<>
        			{(!errorEnv && !errorMe) ?
	        			<Suspense fallback={<SiteLoader />}>
				        	<Routes>
				        		{dataMe && dataMe.me && dataMe.me.tags.find(item => item.name === 'need_intro') ?
				        			<>
				        				<Route exact path="/" element={<PrivateRoute><NewSite {...globalProps} first={true} /></PrivateRoute>} />
				        			</>
				        		:
				        			<>
									    <Route exact path="/" element={<PrivateRoute><Dashboard {...globalProps} /></PrivateRoute>} />
						        		<Route exact path="/edit-pages" element={<PrivateRoute><Edit {...globalProps} /></PrivateRoute>} />
						        		<Route exact path="/edit-posts" element={<PrivateRoute><Edit {...globalProps} /></PrivateRoute>} />
						        		<Route exact path="/preview" element={<PrivateRoute><Preview {...globalProps} /></PrivateRoute>} />
										<Route exact path="/post-types" element={<PrivateRoute><PostTypes {...globalProps} /></PrivateRoute>} />
										<Route exact path="/content-blocks" element={<PrivateRoute><ContentModules {...globalProps} /></PrivateRoute>} />
										<Route exact path="/posts" element={<PrivateRoute><Posts {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps" element={<PrivateRoute><Apps {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/cookie-info" element={<PrivateRoute><CookieInfo {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/cookie-consent" element={<PrivateRoute><CookieConsent {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/google-analytics" element={<PrivateRoute><GoogleAnalytics {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/google-recaptcha" element={<PrivateRoute><GoogleRecaptcha {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/form" element={<PrivateRoute><Forms {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/form/requests" element={<PrivateRoute><FormRequests {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/social-links" element={<PrivateRoute><SocialLinks {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/custom-scripts" element={<PrivateRoute><CustomScripts {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/custom-css" element={<PrivateRoute><CustomCSS {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/cta" element={<PrivateRoute><Cta {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/sticky-button" element={<PrivateRoute><StickyButton {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/seo-tools" element={<PrivateRoute><SEOTools {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/seo-tools/redirects" element={<PrivateRoute><Redirects {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/seo-tools/site-verification" element={<PrivateRoute><SiteVerification {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/google-tagmanager" element={<PrivateRoute><GoogleTagmanager {...globalProps} /></PrivateRoute>} />
										<Route exact path="/apps/custom-email" element={<PrivateRoute><CustomEmail {...globalProps} /></PrivateRoute>} />
										<Route exact path="/settings/appearance" element={<PrivateRoute><Appearance {...globalProps} /></PrivateRoute>} />
										<Route exact path="/settings/developer" element={<PrivateRoute><Developer {...globalProps} /></PrivateRoute>} />
										<Route exact path="/settings/domains" element={<PrivateRoute><Domains {...globalProps} /></PrivateRoute>} />
						        		{dataEnv.env.billingEnabled &&
						        			<Route exact path="/settings/billing" element={<PrivateRoute><Billing {...globalProps} /></PrivateRoute>} />
						        		}
										<Route exact path="/settings/user" element={<PrivateRoute><User {...globalProps} /></PrivateRoute>} />
						        		{dataEnv.env.canCreateNewSites &&
						        			<Route exact path="/new-site" element={<PrivateRoute><NewSite {...globalProps} /></PrivateRoute>} />
						        		}
										<Route exact path="/payment-successful" element={<PrivateRoute><PaymentSuccessful {...globalProps} /></PrivateRoute>} />
										<Route exact path="/media" element={<PrivateRoute><Media {...globalProps} /></PrivateRoute>} />
										<Route exact path="/admin/users" element={<PrivateRoute><AdminUsers {...globalProps} /></PrivateRoute>} />
										<Route exact path="/admin/partners" element={<PrivateRoute><AdminPartners {...globalProps} /></PrivateRoute>} />
										<Route exact path="/admin/owners" element={<PrivateRoute><AdminOwners {...globalProps} /></PrivateRoute>} />
										<Route exact path="/admin/domains" element={<PrivateRoute><AdminDomains {...globalProps} /></PrivateRoute>} />
										<Route exact path="/admin/languages" element={<PrivateRoute><AdminLanguages {...globalProps} /></PrivateRoute>} />
										<Route exact path="/login" element={<Login {...globalProps} />} />
										<Route exact path="/request-password" element={<RequestPassword {...globalProps} />} />
										<Route exact path="/reset-password" element={<ResetPassword {...globalProps} />} />
						        		{dataEnv.env.allowNewRegistrations &&
							        		<Route exact path="/register" element={<Register {...globalProps} />} />
						        		}
										<Route exact path="/more" element={<PrivateRoute><More {...globalProps} /></PrivateRoute>} />
										<Route exact path="/change-email" element={<ChangeEmail {...globalProps} />} />
										<Route exact path="/owner/sectors" element={<PrivateRoute><OwnerSectors {...globalProps} /></PrivateRoute>} />
										<Route exact path="/owner/partners" element={<PrivateRoute><OwnerPartners {...globalProps} /></PrivateRoute>} />
										<Route exact path="/owner/sites" element={<PrivateRoute><OwnerSites {...globalProps} /></PrivateRoute>} />
										<Route exact path="/team" element={<PrivateRoute><Team {...globalProps} /></PrivateRoute>} />
										<Route exact path="/roles" element={<PrivateRoute><Roles {...globalProps} /></PrivateRoute>} />
										<Route exact path="/invitations" element={<PrivateRoute><Invitations {...globalProps} /></PrivateRoute>} />
										<Route exact path="/notifications" element={<PrivateRoute><Notifications {...globalProps} /></PrivateRoute>} />
										<Route exact path="/notifications/:id/single" element={<PrivateRoute><Notification {...globalProps} /></PrivateRoute>} />
				        			</>
				        		}
				        		<Route exact path="/verify-email" element={<VerifyEmail {...globalProps} />} />
				        		<Route exact path="/accept-invitation" element={<AcceptInvitation {...globalProps} />} />
				        	</Routes>
				        </Suspense>
				    :
				    	<SiteError />
				    }
        		</>
        	}
        </BrowserRouter>
	);
}

export default App;