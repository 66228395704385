import { gql } from '@apollo/client';

export const ME_QUERY = gql`
    query Me {
        me {
            id
            name
            customerId
            email
            emailVerified
            roles {
                id
                name
            }
            isAbleTo
            workingSite {
                id
                name
                langDefault
                token
                logo {
                    id
                    filename
                    original
                }
                footerLogo {
                    id
                    filename
                    original
                }
                favicon {
                    id
                    filename
                    original
                }
                primaryDomain {
                    domain
                }
                settings
                fontBody {
                    id
                }
                fontHeadlines {
                    id
                }
                fontNavi {
                    id
                }
                apps {
                    id
                    name
                    slug
                    settings
                    page
                }
                theme {
                    id
                    hasDemoData
                    templates
                }
                spaceType
                sector {
                    id
                }
                role
                trialDays
                subscriptions {
                    id
                    name
                    product
                    status
                    stripe_id
                },
                canUseStarterPackage
                postsNeedReviewCount
            }
            sites {
                id
                name
            }
            mySites {
                id
                name
            }
            todoList {
                id
                slug
                name
                app {
                    id
                    name
                }
            }
            tags {
                id
                name
            }
            tooltips {
                id
                name
                tour {
                    id
                    name
                }
            }
            unreadNotifications
        }
    }
`;

export const SUBSCRIPTIONS_QUERY = gql`
    query Subscriptions {
        subscriptions {
            id
            name
            product
            status
            stripe_id
            stripeObject
        }
    }
`;

export const APP_SEARCH_QUERY = gql`
    query AppSearch {
        appSearch {
            pages {
                id
                name
                icon
            },
            posts {
                id
                name
                icon
            },
            apps {
                id
                name
                slug
                page
            }
            functions {
                name
                url
                tags
                icon
            }
        }
    }
`;

export const ADMIN_USERS_TABLE_QUERY = gql`
    query AdminUsersTableQuery($first:Int, $page:Int, $query:String, $guests:Boolean, $withoutActiveSubscription:Boolean, $customerId:String, $email:String){
        adminUsers(first:$first, page:$page, query:$query, guests:$guests, withoutActiveSubscription:$withoutActiveSubscription, customerId:$customerId, email:$email){
            data {
                id
                name
                email
                customerId
                created_at
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const ADMIN_USER_BY_ID_QUERY = gql`
    query AdminUser($id:ID){
        adminUser(id:$id){
            id
            name
            email
            roles {
                value:name
            }
        }
    }
`;

export const ADMIN_USERS_SELECT_QUERY = gql`
    query AdminUsersSelectQuery($first:Int, $page:Int, $query:String, $selected: ID){
        options:adminUsers(first:$first, page:$page, query:$query){
            data {
                id
                name
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
        selected:adminUser(id:$selected){
            id
            name
        }
    }
`;