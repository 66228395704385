import _ from 'lodash';
import styles from '../../Editor/Editor.module.scss';

const initialState = {
	dataObject:null,
	objectType:null,
	deepPath:null,
	silent:false,
	loadPageId:null,
	loadPageTitle:null,
	clipboard:null
}

export default function editorReducer(state = initialState, action){
	switch(action.type){
		case 'editor/editDataObject':
			return {
				...state,
				dataObject:typeof action.merge === 'string' ? (_.setWith(JSON.parse(JSON.stringify(state.dataObject)), action.merge, _.get(JSON.parse(JSON.stringify(action.dataObject)), action.merge))) : (action.merge === false ? action.dataObject : _.merge(JSON.parse(JSON.stringify(state.dataObject)), action.dataObject)),
				objectType:action.objectType || state.objectType,
				deepPath:null,
				silent:false
			};
		case 'editor/goToPage':
			return {
				...state,
				deepPath:action.deepPath
			};
		case 'editor/resetEditor':
	        const pagesWrapper = document.querySelector('.'+styles.Pages);
	        if (pagesWrapper){
		        pagesWrapper.classList.add(styles['Pages--no-transition']);
		        pagesWrapper.removeAttribute('style');
				Array.from(document.querySelectorAll('.'+styles.Page)).map((item, index) => {
		            if (index > 0){
		            	item.remove();
		            }
		        });
		        setTimeout(function(){
		        	pagesWrapper.classList.remove(styles['Pages--no-transition']);
		        }, 310);
		    }
			return {
				...state,
				dataObject:null,
				objectType:null,
				deepPath:null
			};
		case 'editor/clearDataObject':
	        return {
				...state,
				dataObject:null,
				objectType:null,
				deepPath:null,
				silent:true
			};
		case 'editor/loadEditorPage':
	        return {
				...state,
				loadPageId:action.pageId,
				loadPageTitle:action.pageTitle
			};
		case 'editor/copyToClipboard':
	        return {
				...state,
				clipboard:action.data
			};
		default:
			return state;
	}
}