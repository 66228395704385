import styles from './Badge.module.scss';
import SVG from 'react-inlinesvg';

const Badge = (props) => {
	function classNames(){
		const classNames = [styles.Wrapper];
		if (props.color){
			classNames.push(styles['Wrapper--'+props.color]);
		}
		if (props.rounded){
			classNames.push(styles['Wrapper--rounded-'+props.rounded]);
		}
		if (props.size){
			classNames.push(styles['Wrapper--'+props.size]);
		}
		if (!props.label && props.icon){
			classNames.push(styles['Wrapper--icon-only']);
		}
		if (props.position === 'absolute'){
			classNames.push(styles['Wrapper--absolute']);
		}
		return classNames.join(' ');
	}

	return (
		<div className={classNames()}>
			<div className={styles.Badge}>
				<div className={styles.Body}>
					{props.icon && (props.iconPos === undefined || props.iconPos == 'before') && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
					{props.label && <div className={styles.Label}>{props.label}</div>}
					{props.icon && props.iconPos == 'after' && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
				</div>
			</div>
		</div>
	)
}

export default Badge;